import React, { useState } from 'react';
import { Container, Button, Modal, Form } from 'react-bootstrap';
import NewUserSetupCard from './NewUserSetupCard';
import { useNavigate } from 'react-router-dom'; // Add this import to define `navigate`
import data_user from '../data/data_user';
import defaultAvatar from '../assets/img/avatars/avatar_alien_white.png';
import { useAuth } from './AuthContext'; // Import useAuth

function MockSignInPage() {
  const { signIn } = useAuth(); // Access signIn function
  const [showSignUp, setShowSignUp] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInError, setSignInError] = useState('');
  const [avatar, setAvatar] = useState(defaultAvatar);
  const [originalImage, setOriginalImage] = useState(defaultAvatar);
  const [currentImage, setCurrentImage] = useState(defaultAvatar);
  const navigate = useNavigate();

  console.log(avatar); // Ensure avatar is accessed to avoid ESLint warning

  const handleSignIn = () => {
    const existingUser = data_user.find(
      (user) =>
        (user.username === usernameOrEmail || user.email === usernameOrEmail) &&
        user.password === password
    );

    if (existingUser) {
      signIn(existingUser.username, existingUser.email); // Update AuthContext
      setCurrentUser(existingUser);
      setAvatar(existingUser.avatar || defaultAvatar);
      setOriginalImage(existingUser.avatar || defaultAvatar);
      setCurrentImage(existingUser.avatar || defaultAvatar);
      setShowSignInModal(false); // Close modal on successful sign-in
      navigate('/progress'); // Redirect to Progress page
    } else {
      setSignInError('Invalid username/email or password');
    }
  };

  const handleNewUserSignup = () => {
    setShowSignUp(true); // Show New User Setup Card
  };

  const handleUserCreated = (newUser) => {
    data_user.push(newUser); // Add new user to data_user
    setCurrentUser(newUser); // Set current user
    setAvatar(newUser.avatar || defaultAvatar);
    setOriginalImage(newUser.avatar || defaultAvatar);
    setCurrentImage(newUser.avatar || defaultAvatar);
    setShowSignUp(false); // Exit signup view
  };

  return (
    <div className="page">
      <Container fluid className="auth-container">
        {!currentUser && !showSignUp ? (
          <div className="center-button-container">
            <Button className="mock-sign-link" onClick={handleNewUserSignup}>
              New User Sign up
            </Button>
            <Button className="mock-sign-link" onClick={() => setShowSignInModal(true)}>
              Mock Sign-In
            </Button>
          </div>
        ) : showSignUp ? (
          <NewUserSetupCard
            avatar={currentImage}
            setAvatar={(newImage) => {
              setCurrentImage(newImage);
              setAvatar(newImage);
            }}
            originalImage={originalImage}
            setOriginalImage={(newOriginal) => {
              setOriginalImage(newOriginal);
              setCurrentImage(newOriginal);
              setAvatar(newOriginal);
            }}
            onUserCreated={handleUserCreated}
          />
        ) : (
          null
        )}
          </Container>

        {/* Sign-In Modal */}
        <Modal show={showSignInModal} onHide={() => setShowSignInModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Mock Sign-In</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="mock-signin-form" onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission
              handleSignIn(); // Call the sign-in function
            }}>
              <Form.Group controlId="formUsernameOrEmail">
                <Form.Label>Username or Email</Form.Label>
                <Form.Control
                  type="text"
                  value={usernameOrEmail}
                  onChange={(e) => setUsernameOrEmail(e.target.value)}
                  placeholder="Enter username or email"
                />
              </Form.Group>
              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                />
              </Form.Group>
              {signInError && <p style={{ color: 'red' }}>{signInError}</p>}
              <Button className="mock-signin-button" type="submit">
                Sign In
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
    </div>
  );
}

export default MockSignInPage;
