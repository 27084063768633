const data_program = [
  { level: "Level 1", progression: "1a - Pushups x 25", progress_code: "l1a", goal: 25, color: "white", videoUrl: "https://www.youtube.com/embed/dvfL1tywENc" },
  { level: "Level 1", progression: "1b - Plank x 60s", progress_code: "l1b", goal: 60, color: "white", videoUrl: "https://www.youtube.com/embed/imjRptwHP-k" },
  { level: "Level 1", progression: "1c - Dips x 15", progress_code: "l1c", goal: 15, color: "white", videoUrl: "https://www.youtube.com/embed/ZpzTssVUE7U" },
  { level: "Level 2", progression: "2a - Pike PU x 10", progress_code: "l2a", goal: 10, color: "rgb(155, 134, 17)" },
  { level: "Level 2", progression: "2b - Wall HS x 60s", progress_code: "l2b", goal: 60, color: "rgb(155, 134, 17)" },
  { level: "Level 2", progression: "2c - TriPod Raise x 10", progress_code: "l2c", goal: 10, color: "rgb(155, 134, 17)" },
  { level: "Level 3", progression: "3a - Pike PU Raise x 10", progress_code: "l3a", goal: 10, color: "rgb(16, 16, 89)" },
  { level: "Level 3", progression: "3b - Crow x 60s", progress_code: "l3b", goal: 60, color: "rgb(16, 16, 89)" },
  { level: "Level 3", progression: "3c - TriPod Kip x 10", progress_code: "l3c", goal: 10, color: "rgb(16, 16, 89)" },
  { level: "Level 4", progression: "4a - Face wHSPU x 10", progress_code: "l4a", goal: 10, color: "rgb(24, 62, 24)" },
  { level: "Level 4", progression: "4b - Pike Float x 10", progress_code: "l4b", goal: 10, color: "rgb(24, 62, 24)" },
  { level: "Level 4", progression: "4c - Free HS x 30s", progress_code: "l4c", goal: 30, color: "rgb(24, 62, 24)" },
  { level: "Level 5", progression: "5a - TPod kip HS x 10", progress_code: "l5a", goal: 10, color: "rgb(68, 13, 13)" },
  { level: "Level 5", progression: "5b - Pike Float Ext x 10", progress_code: "l5b", goal: 10, color: "rgb(68, 13, 13)" },
  { level: "Level 5", progression: "5c - HS to Tripod x 10", progress_code: "l5c", goal: 10, color: "rgb(68, 13, 13)" },
  { level: "Level 6", progression: "6a - TPod kHS TPod x 10", progress_code: "l6a", goal: 10, color: "#1f1f1f" },
  { level: "Level 6", progression: "6b - Pike Float HS x 10", progress_code: "l6b", goal: 10, color: "#1f1f1f" },
  { level: "Level 6", progression: "6c - HSPU x 5", progress_code: "l6c", goal: 5, color: "#1f1f1f" }
];

export default data_program;