import { React } from "react";
import { Container } from "react-bootstrap";
import ProfilePage from "./ProfilePage";
import "../css/Home.css";
import "../css/Progress.css";
import "../css/ComingSoon.css";
import { Helmet } from "react-helmet";

function Progress() {

  const video_row_4 =
  "https://t4abucket225336-staging.s3.us-east-2.amazonaws.com/videos_rope.mp4";

  return (
    <div className="page">
      <Helmet>
        <title>thenics4all</title>
        <meta
          name="description"
          content="Step-by-step Handstand pushup progressions, training and tutorials."
        />
        <meta
          name="keywords"
          content="handstand, handstand pushup, hspu, calisthenics, progression, tutorial"
        />
      </Helmet>
      <Container fluid>
      <div className="home-row">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="home-row-video-background"
          >
            <source src={video_row_4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h1>
            <a className="home-link" href="/progress">
              Track Progress
            </a>
          </h1>
          <h2 className="home-row-subtitle">
          <a className="home-link-join" href="/login">Join</a> to create your profile
          </h2>
          <ProfilePage />
        </div>
      </Container>
    </div>
  );
}
export default Progress;