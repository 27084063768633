import React, { useState, useEffect } from 'react';
import { Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import { FaYoutube } from 'react-icons/fa';
import defaultAvatar from '../assets/img/avatars/avatar_alien_white.png';
import badge1 from '../assets/img/badges/1_badge_rabbit.png';
import badge2 from '../assets/img/badges/2_badge_deer.png';
import badge3 from '../assets/img/badges/3_badge_wolf.png';
import badge4 from '../assets/img/badges/4_badge_horse.png';
import badge5 from '../assets/img/badges/5_badge_bear.png';
import badge6 from '../assets/img/badges/6_badge_gorilla.png';
import badge7 from '../assets/img/badges/7_badge_lion.png';
import '../css/ProfilePage.css';
import CardHSPUProgressProfile from './CardHSPUProgressProfile';
import data_program from '../data/data_program';
import data_user from '../data/data_user';
import { useNavigate } from 'react-router-dom';


function ProfilePage({ avatar: currentImage }) {
  const [journeyCompletion, setJourneyCompletion] = useState(0); // State for total journey completion
  const [currentBadge, setCurrentBadge] = useState(badge1); // State for current badge image
  const [badgeLevel, setBadgeLevel] = useState('Level 1'); // State for current badge level
  const [dynamicBadges, setDynamicBadges] = useState([]);
  const { user, isAuthenticated } = useAuth(); // Access AuthContext
  const [searchTerm, setSearchTerm] = useState(
    isAuthenticated && user.username ? user.username : 'zarrar'
  );
  const [displayedUser, setDisplayedUser] = useState(
    data_user.find((u) => u.username === (isAuthenticated && user.username ? user.username : 'zarrar'))
  );
  const [favorites, setFavorites] = useState({});
  const [isFavorite, setIsFavorite] = useState(false); // Local state for favorite status


  useEffect(() => {
    const storedFavorites = JSON.parse(sessionStorage.getItem("favorites")) || {};
    setFavorites(storedFavorites);
  }, []);

  useEffect(() => {
    if (displayedUser) {
      setIsFavorite(favorites[displayedUser.username] || false);
    }
  }, [favorites, displayedUser]);

  const navigate = useNavigate();

  const handleProgressUpdate = (updatedProgress) => {
    setDisplayedUser((prevUser) => ({
      ...prevUser,
      progressData: updatedProgress,
    }));
  };

  useEffect(() => {
    // Automatically set searchTerm to the authenticated user's name
    if (isAuthenticated && user.username) {
      setSearchTerm(user.username); // Update search term to the signed-in user's username
      setDisplayedUser(data_user.find((u) => u.username === user.username)); // Update displayed user
    }
  }, [isAuthenticated, user.username]);


  useEffect(() => {
    const storedUser = JSON.parse(sessionStorage.getItem('selectedUser')); // Get selected user from sessionStorage

    if (storedUser) {
      setDisplayedUser(storedUser);
      setSearchTerm(storedUser.username); // Sync the search box with the selected user
    } else if (searchTerm) {
      const matchedUser = data_user.find((u) =>
        u.username.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setDisplayedUser(matchedUser || null); // Show "User not found" if no match
    } else if (isAuthenticated && user.username) {
      const defaultUser = data_user.find((u) => u.username === user.username);
      setDisplayedUser(defaultUser);
      if (searchTerm !== "") {
        setSearchTerm(defaultUser?.username || ""); // Sync search box with authenticated user
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, isAuthenticated, user.username]);

  useEffect(() => {
    // Clear the selected user from sessionStorage after it's loaded
    sessionStorage.removeItem('selectedUser');
  }, []);

  displayedUser ? (
    <h3>{displayedUser.username}</h3>
  ) : (
    <h3>User not found</h3>
  );

  useEffect(() => {
    if (!currentImage) {
      // Handle fallback logic if required
    }
  }, [currentImage]);

  useEffect(() => {
    if (!displayedUser) return;

    const userProgressData = displayedUser.progressData || [];

    const calculateJourneyCompletion = () => {
      let totalProgress = 0;
      let maxPossibleProgress = 0;

      data_program.forEach((data) => {
        const userProgress = userProgressData.find(
          (item) => item.progress_code === data.progress_code
        );

        const progressPercentage = userProgress
          ? Math.min((userProgress.best / data.goal) * 100, 100)
          : 0;

        totalProgress += progressPercentage;
        maxPossibleProgress += 100; // Each task contributes 100% max to the journey
      });

      const totalJourneyPercentage = Math.min(
        Math.floor((totalProgress / maxPossibleProgress) * 100),
        100
      );
      setJourneyCompletion(totalJourneyPercentage);
    };

    const updateBadge = () => {
      const badgeImages = [badge1, badge2, badge3, badge4, badge5, badge6, badge7];
      let firstIncompleteLevel = -1;

      for (let level = 0; level < 7; level++) {
        const levelTasks = data_program.filter((data) => data.level === `Level ${level + 1}`);

        const levelComplete = levelTasks.every((data) => {
          const userProgress = userProgressData.find(
            (item) => item.progress_code === data.progress_code
          );

          const progressPercentage = userProgress
            ? Math.min((userProgress.best / data.goal) * 100, 100)
            : 0;

          return progressPercentage === 100; // Ensure all sublevels are fully complete
        });

        if (!levelComplete) {
          firstIncompleteLevel = level;
          break;
        }
      }

      // Handle case where all levels are complete
      if (firstIncompleteLevel === -1) {
        firstIncompleteLevel = 6; // Set to the highest level
      }

      setCurrentBadge(badgeImages[firstIncompleteLevel]); // Use the correct badge image
      setBadgeLevel(`Level ${firstIncompleteLevel + 1}`); // Update badge level

      // Dynamically update badges
      const updatedBadges = badgeImages.map((image, index) => ({
        level: `Level ${index + 1}`,
        image,
        unlocked: index <= firstIncompleteLevel,
      }));
      setDynamicBadges(updatedBadges); // New state to hold updated badges
    };

    calculateJourneyCompletion();
    updateBadge();
  }, [displayedUser]);

  const getProgressBarColor = (percentage) => {
    if (percentage < 25) return '#E74C3C';
    if (percentage >= 25 && percentage < 50) return '#E67E22';
    if (percentage >= 50 && percentage < 75) return '#F1C40F';
    if (percentage >= 75 && percentage < 100) return '#3498DB';
    return '#2ECC71';
  };

  return (
    <Container className="profilepage-card-container compact">
      <div className="profilepage-card-header">
        {/* YouTube Icon */}
        <div className="youtube-link-container">
          <a
            href="www.thenics4all.com"
            target="_blank"
            rel="noopener noreferrer"
            className="youtube-link"
          >
            <FaYoutube className="youtube-icon" />
          </a>
        </div>

        <div className="profilepage-header-controls">
          {isAuthenticated && (
            <button
              className="profilepage-header-button"
              onClick={() => setSearchTerm(user.username)}
            >
              Me
            </button>
          )}
          <input
            type="text"
            placeholder="Search by username"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="profilepage-search-input"
          />
          <button
            className="profilepage-header-button"
            onClick={() => navigate('/community')}
          >
            All
          </button>
        </div>
        <div
          className={`profilepage-heart-icon ${isFavorite ? "favorited" : "unfavorited"
            }`}
          onClick={() => {
            if (!displayedUser) return;
            const updatedFavorites = { ...favorites, [displayedUser.username]: !favorites[displayedUser.username] };
            setFavorites(updatedFavorites);
            sessionStorage.setItem("favorites", JSON.stringify(updatedFavorites));
          }}
        >
          ❤
        </div>
      </div>
      <div className="profilepage-card-content">
        <div className="profilepage-avatar-badge-container">
          <div className="profilepage-avatar-container">
            {displayedUser && (
              <div className="profilepage-avatar-label">
                <img
                  src={displayedUser.avatar || defaultAvatar}
                  alt="Avatar"
                  className="profilepage-avatar-image"
                />
              </div>
            )}
            <div className="profilepage-handle-container">
              {displayedUser ? (
                <h3>{displayedUser.username}</h3>
              ) : (
                <h3>User not found</h3>
              )}
            </div>
          </div>

          <div className="profilepage-details-container">
            <div className="profilepage-badge-container">
              {dynamicBadges
                .filter((badge) => badge.level !== badgeLevel) // Exclude the current badge level
                .slice(0, 6) // Limit to the first 6 badges
                .map((badge, index) => (
                  <OverlayTrigger
                    key={index}
                    placement="top"
                    overlay={<Tooltip>{badge.level}</Tooltip>}
                  >
                    <img
                      src={badge.image}
                      alt={badge.level}
                      className={`profilepage-badge-image ${badge.unlocked ? '' : 'profilepage-locked-badge'}`}
                    />
                  </OverlayTrigger>
                ))}
            </div>
          </div>

          <div className="profilepage-current-badge-container">
            <img
              src={currentBadge}
              alt={badgeLevel}
              className="profilepage-current-badge-image profilepage-no-circle"
            />
            <div className="profilepage-current-badge-label">{badgeLevel}</div>
          </div>
        </div>

        <div className="profilepage-journey-completion-container">
          <div className="profilepage-journey-completion-header">
            <div className="profilepage-journey-completion-label">Journey</div>
            <div className="profilepage-journey-completion-percentage">{journeyCompletion}%</div>
          </div>
          <div className="profilepage-journey-completion-bar">
            <div
              className="profilepage-journey-completion-fill"
              style={{
                width: `${journeyCompletion}%`,
                backgroundColor: getProgressBarColor(journeyCompletion),
              }}
            ></div>
          </div>
        </div>

        <div className="profilepage-progress-card-container">
          {displayedUser ? (
            <CardHSPUProgressProfile
              data_user={[displayedUser]}
              isOwnProfile={isAuthenticated && user.username === displayedUser.username}
              onUpdateProgress={handleProgressUpdate}
            />
          ) : (
            <h3>User not found</h3>
          )}
        </div>
      </div>
    </Container>
  );
}

export default ProfilePage;
